<template>
	<div>
		<div class="js-copy js-content pd_body">
			<headerNav :currentPath="'CryptoFi'"> </headerNav>

			<div class="f_home f_item">
				<div class="f_home_1">
					<img
						class="pointer f_home_1_img"
						src="https://res.metaera.hk/resources/assets/images/summit/文案tou@3x 2.png"
						alt=""
						@click="buyBtnClick('https://lu.ma/MetaEra')"
					/>
					<div class="f_home_link">
						<img
							class="pointer"
							src="https://res.metaera.hk/resources/assets/images/summit/telegram (2)@3x.png"
							@click="showUrl('https://t.me/MetaEraHK')"
						/>
						<img
							class="pointer"
							src="https://res.metaera.hk/resources/assets/images/summit/tuite-copy@3x.png"
							@click="showUrl('https://x.com/MetaEraCN')"
						/>
						<img
							class="pointer"
							src="https://res.metaera.hk/resources/assets/images/summit/邮件,邮箱_jurassic@3x.png"
							@click="sendEmail('event@metaera.hk')"
						/>
					</div>
				</div>

				<div class="f_home_5">
					<img
						src="https://res.metaera.hk/resources/assets/images/forum/65.png"
						alt=""
					/>
				</div>
				<div class="f_next" @click="next(0)">
					<img
						src="https://res.metaera.hk/resources/assets/images/forum/51.png"
						alt=""
					/>
				</div>
			</div>

			<div class="f_value f_item">
				<div class="f_value_top">
					<div class="f_item_header">
						<div class="f_item_header_1"> 價值觀 </div>
					</div>
					<div class="f_item_body_text f_item_body_text_11">
						解碼加密貨幣，重塑金融未來。<br />
						Decoding Cryptocurrency, Reshaping the Future of Finance.
					</div>
				</div>
				<div class="f_item_header_4">
					<div class="f_item_header_3"> 使命</div>
					<div class="f_item_header_2"> </div>
				</div>

				<div class="f_item_body_text f_item_body_text3"
					>“香港加密金融论坛”（CryptoFi Forum Hong Kong,
					CFF）致力于搭建一个促进全球加密金融行业发展的对话与合作平台，邀请来自政府及监管部门、高校、学术研究与咨询机构、主流金融机构及资产管理平台的代表，以及活跃在相关领域的分析师、影响力人物、商业领袖齐聚一堂，共同探讨关于加密金融、Web
					3.0 技术的前沿方向与合规应用，推动整个行业的合规化、规模化发展。</div
				>
				<div class="f_next" @click="next(1)">
					<img
						src="https://res.metaera.hk/resources/assets/images/forum/2.png"
						alt=""
					/>
				</div>
			</div>

			<div class="f_about f_item">
				<div class="f_about_5">
					<div class="f_about_5_item">
						<div class="f_about_5_item1">
							<img
								src="https://res.metaera.hk/resources/assets/images/forum/6.png"
								alt=""
							/>
						</div>
						<div class="f_about_5_item2">10,000+ </div>
						<div class="f_about_5_item3">參與者 </div>
					</div>
					<div class="f_about_5_item">
						<div class="f_about_5_item1">
							<img
								src="https://res.metaera.hk/resources/assets/images/forum/7_1.png"
								alt=""
							/>
						</div>
						<div class="f_about_5_item2">190+ </div>
						<div class="f_about_5_item3">演講嘉賓 </div>
					</div>
					<div class="f_about_5_item">
						<div class="f_about_5_item1">
							<img
								src="https://res.metaera.hk/resources/assets/images/forum/8.png"
								alt=""
							/>
						</div>
						<div class="f_about_5_item2">80+ </div>
						<div class="f_about_5_item3">贊助商 </div>
					</div>
					<div class="f_about_5_item">
						<div class="f_about_5_item1">
							<img
								src="https://res.metaera.hk/resources/assets/images/forum/24.png"
								alt=""
							/>
						</div>
						<div class="f_about_5_item2">350+ </div>
						<div class="f_about_5_item3">合作夥伴 </div>
					</div>
				</div>

				<div class="f_about_6">
					<div class="f_about_5_item">
						<div class="f_about_5_item1">
							<img
								src="https://res.metaera.hk/resources/assets/images/forum/6.png"
								alt=""
							/>
						</div>
						<div class="f_about_5_item2">10,000+ </div>
						<div class="f_about_5_item3">参与者 </div>
					</div>
					<div class="f_about_5_item">
						<div class="f_about_5_item1">
							<img
								src="https://res.metaera.hk/resources/assets/images/forum/7_1.png"
								alt=""
							/>
						</div>
						<div class="f_about_5_item2">200+ </div>
						<div class="f_about_5_item3">演讲嘉宾 </div>
					</div>
				</div>

				<div class="f_about_7">
					<div class="f_about_5_item">
						<div class="f_about_5_item1">
							<img
								src="https://res.metaera.hk/resources/assets/images/forum/8.png"
								alt=""
							/>
						</div>
						<div class="f_about_5_item2">100+ </div>
						<div class="f_about_5_item3">参展商 </div>
					</div>
					<div class="f_about_5_item">
						<div class="f_about_5_item1">
							<img
								src="https://res.metaera.hk/resources/assets/images/forum/24.png"
								alt=""
							/>
						</div>
						<div class="f_about_5_item2">100+ </div>
						<div class="f_about_5_item3">合作伙伴 </div>
					</div>
				</div>
				<div class="f_next" @click="next(5)">
					<img
						src="https://res.metaera.hk/resources/assets/images/forum/2.png"
						alt=""
					/>
				</div>
			</div>

			<div class="f_add f_item">
				<div class="f_item_header_4">
					<div class="f_item_header_3"> 参与群体</div>
					<div class="f_item_header_2"> </div>
				</div>
				<div class="f_add_content">
					<div class="f_add_content_left">
						<div>
							<div class="f_add_content_left_span_1">金融机构 </div>
							<div class="f_add_content_left_span_2"
								>银行与合规托管商、合规交易平台、经纪商、支付平台、保险商和金融科技公司等
							</div>
						</div>
						<div>
							<div class="f_add_content_left_span_1">资产管理人 </div>
							<div class="f_add_content_left_span_2"
								>风险投资公司、对冲基金、资产管理平台、家族办公室、量化与流动性管理团队等
							</div>
						</div>
						<div>
							<div class="f_add_content_left_span_1">政府及监管部门 </div>
							<div class="f_add_content_left_span_2"> </div>
							<div class="f_add_content_left_span_1"
								>高校、学术研究与资讯机构
							</div>
						</div>
					</div>
					<div class="f_add_content_right">
						<img
							src="https://res.metaera.hk/resources/assets/images/summit/1280X1280@3x.png"
							alt=""
						/>
					</div>
				</div>
			</div>

			<div class="f_video f_item">
				<div class="f_item_header_video">
					2025年4月8日，加入我们在香港举办的精彩活动，与行业关键人物共同探索加密领域的前沿洞见。
					观看这段视频，抢先一睹今年活动的精彩瞬间！
				</div>
				<div class="f_232_5 pointer">
					<video
						ref="videoPlayer_top"
						controls
						muted
						autoplay
						loop
						:poster="posterUrlTop"
						@play="hideOverlayTop"
						@canplay="handleCanPlayTop"
					>
						<source :src="videoUrlTop" type="video/mp4" />
					</video>
					<div v-if="showOverlayTop" class="video-overlay">
						<img
							:src="posterUrlTop"
							@click="playVideoTop"
							class="video-overlay-bg"
						/>
						<img
							src="https://res.metaera.hk/resources/assets/images/forum/45.png"
							@click="playVideoTop"
							class="video-overlay-play_top"
						/>
						<!-- <div class="bottom-left-container" @click="playVideoYTBTop">
							<div class="info-box">
								<span class="info-text">PLATFORM TO WATCH</span>
								<img
									src="https://res.metaera.hk/resources/assets/images/event/54.png"
									class="info-icon"
								/>
							</div>
						</div> -->
					</div>
				</div>
				<div class="f_video_bottom"></div>
			</div>

			<div class="f_232_prople f_item">
				<div class="f_item_header_4">
					<div class="f_item_header_3"> 重磅嘉宾</div>
					<div class="f_item_header_2"> </div>
				</div>
				<div class="f_231_5">
					<div class="f_231_5_list">
						<div class="f_231_5_item f_231_5_item_1">
							<div class="f_231_5_item_img">
								<img
									src="https://res.metaera.hk/resources/assets/images/summit/speaker/陈浩濂 太平绅士.png"
									alt=""
								/>
							</div>
							<div class="f_231_5_item_name">
								<img
									src="https://res.metaera.hk/resources/assets/images/forum/12.png"
									alt=""
								/>
								<span> 陈浩濂 太平绅士 </span>
							</div>
							<div class="f_231_5_item_content">
								香港特别行政区政府财经事务及库税局副局长
							</div>
						</div>
						<div class="f_231_5_item f_231_5_item_1">
							<div class="f_231_5_item_img">
								<img
									src="https://res.metaera.hk/resources/assets/images/summit/speaker/程和木 IDSM.png"
									alt=""
								/>
							</div>
							<div class="f_231_5_item_name">
								<img
									src="https://res.metaera.hk/resources/assets/images/forum/12.png"
									alt=""
								/>
								<span> 程和木 IDSM </span>
							</div>
							<div class="f_231_5_item_content">
								香港特别行政区政府署理入境事务处处长
							</div>
						</div>
						<div class="f_231_5_item f_231_5_item_1">
							<div class="f_231_5_item_img">
								<img
									src="https://res.metaera.hk/resources/assets/images/summit/speaker/吴杰庄博士  MH, JP.png"
									alt=""
								/>
							</div>
							<div class="f_231_5_item_name">
								<img
									src="https://res.metaera.hk/resources/assets/images/forum/12.png"
									alt=""
								/>
								<span> 吴杰庄博士 MH, JP </span>
							</div>
							<div class="f_231_5_item_content">
								香港特别行政区立法会议员<br />
								全国政协委员<br />
								Web3虚拟资产发展事宜小组委员会主席
							</div>
						</div>
						<div class="f_231_5_item f_231_5_item_1">
							<div class="f_231_5_item_img">
								<img
									src="https://res.metaera.hk/resources/assets/images/summit/speaker/邓家彪, BBS, JP.png"
									alt=""
								/>
							</div>
							<div class="f_231_5_item_name">
								<img
									src="https://res.metaera.hk/resources/assets/images/forum/12.png"
									alt=""
								/>
								<span> 邓家彪, BBS, JP </span>
							</div>
							<div class="f_231_5_item_content"> 香港特别行政区立法会议员 </div>
						</div>
						<div class="f_231_5_item f_231_5_item_1">
							<div class="f_231_5_item_img">
								<img
									src="https://res.metaera.hk/resources/assets/images/summit/speaker/蔡洪滨.png"
									alt=""
								/>
							</div>
							<div class="f_231_5_item_name">
								<img
									src="https://res.metaera.hk/resources/assets/images/forum/12.png"
									alt=""
								/>
								<span> 蔡洪滨 </span>
							</div>
							<div class="f_231_5_item_content">
								香港大学经管学院院长及经济学讲座教授
							</div>
						</div>
						<div class="f_231_5_item f_231_5_item_1">
							<div class="f_231_5_item_img">
								<img
									src="https://res.metaera.hk/resources/assets/images/summit/speaker/林晨.png"
									alt=""
								/>
							</div>
							<div class="f_231_5_item_name">
								<img
									src="https://res.metaera.hk/resources/assets/images/forum/12.png"
									alt=""
								/>
								<span> 林晨 </span>
							</div>
							<div class="f_231_5_item_content">
								香港大学协理副校长 <br />香港大学经管学院副院长
							</div>
						</div>

						<!-- <div class="f_231_5_item f_231_5_item_1">
								<div class="f_231_5_item_img">
									<img
										src="https://res.metaera.hk/resources/assets/images/summit/20250326-125121.png"
										alt=""
									/>
								</div>
								<div class="f_231_5_item_name">
									<img
										src="https://res.metaera.hk/resources/assets/images/forum/12.png"
										alt=""
									/>
									<span> 赵长鹏（CZ） </span>
								</div>
								<div class="f_231_5_item_content">
									币安联合创始人<br />前首席执行官
								</div>
							</div> -->
						<div class="f_231_5_item f_231_5_item_1">
							<div class="f_231_5_item_img">
								<img
									src="https://res.metaera.hk/resources/assets/images/summit/speaker/孔剑平.png"
									alt=""
								/>
							</div>
							<div class="f_231_5_item_name">
								<img
									src="https://res.metaera.hk/resources/assets/images/forum/12.png"
									alt=""
								/>
								<span> 孔剑平 </span>
							</div>
							<div class="f_231_5_item_content">
								Nano Labs 创始人<br />
								香港数码港董事<br />
								香港第三代互联网(Web3.0)发展专责小组成员
							</div>
						</div>

						<!-- <div class="f_231_5_item f_231_5_item_1">
								<div class="f_231_5_item_img">
									<img
										src="https://res.metaera.hk/resources/assets/images/summit/顾荣辉.png"
										alt=""
									/>
								</div>
								<div class="f_231_5_item_name">
									<img
										src="https://res.metaera.hk/resources/assets/images/forum/12.png"
										alt=""
									/>
									<span> 顾荣辉 </span>
								</div>
								<div class="f_231_5_item_content">
									CertiK 联合创始人<br />
									哥伦比亚大学计算机系教授<br />
									香港第三代互联网(Web3.0)发展专责小组成员
								</div>
							</div> -->

						<div class="f_231_5_item f_231_5_item_1">
							<div class="f_231_5_item_img">
								<img
									src="https://res.metaera.hk/resources/assets/images/summit/speaker/谢志坚.png"
									alt=""
								/>
							</div>
							<div class="f_231_5_item_name">
								<img
									src="https://res.metaera.hk/resources/assets/images/forum/12.png"
									alt=""
								/>
								<span> 谢志坚 </span>
							</div>
							<div class="f_231_5_item_content"> 富途证券董事总经理 </div>
						</div>

						<div class="f_231_5_item f_231_5_item_1">
							<div class="f_231_5_item_img">
								<img
									src="https://res.metaera.hk/resources/assets/images/summit/speaker/何一.png"
									alt=""
								/>
							</div>
							<div class="f_231_5_item_name">
								<img
									src="https://res.metaera.hk/resources/assets/images/forum/12.png"
									alt=""
								/>
								<span> 何一</span>
							</div>
							<div class="f_231_5_item_content"> 币安联合创始人 </div>
						</div>

						<div class="f_231_5_item f_231_5_item_1">
							<div class="f_231_5_item_img">
								<img
									src="https://res.metaera.hk/resources/assets/images/summit/speaker/Michael Wu.png"
									alt=""
								/>
							</div>
							<div class="f_231_5_item_name">
								<img
									src="https://res.metaera.hk/resources/assets/images/forum/12.png"
									alt=""
								/>
								<span> Michael Wu </span>
							</div>
							<div class="f_231_5_item_content">
								Amber Group 联合创始人兼首席执行官
							</div>
						</div>

						<div class="f_231_5_item f_231_5_item_1">
							<div class="f_231_5_item_img">
								<img
									src="https://res.metaera.hk/resources/assets/images/summit/speaker/孙宇晨.png"
									alt=""
								/>
							</div>
							<div class="f_231_5_item_name">
								<img
									src="https://res.metaera.hk/resources/assets/images/forum/12.png"
									alt=""
								/>
								<span> 孙宇晨 </span>
							</div>
							<div class="f_231_5_item_content"> TRON 创始人 </div>
						</div>

						<div class="f_231_5_item f_231_5_item_1">
							<div class="f_231_5_item_img">
								<img
									src="https://res.metaera.hk/resources/assets/images/summit/speaker/Roland Sun.png"
									alt=""
								/>
							</div>
							<div class="f_231_5_item_name">
								<img
									src="https://res.metaera.hk/resources/assets/images/forum/12.png"
									alt=""
								/>
								<span> Roland Sun </span>
							</div>
							<div class="f_231_5_item_content">
								Fenbushi Capital 法律总顾问
							</div>
						</div>

						<div class="f_231_5_item f_231_5_item_1">
							<div class="f_231_5_item_img">
								<img
									src="https://res.metaera.hk/resources/assets/images/summit/speaker/Wayne Huo.png"
									alt=""
								/>
							</div>
							<div class="f_231_5_item_name">
								<img
									src="https://res.metaera.hk/resources/assets/images/forum/12.png"
									alt=""
								/>
								<span> Wayne Huo </span>
							</div>
							<div class="f_231_5_item_content">
								Amber Premium 首席执行官 <br />
								Amber Group 联合创始人
							</div>
						</div>

						<div class="f_231_5_item f_231_5_item_1">
							<div class="f_231_5_item_img">
								<img
									src="https://res.metaera.hk/resources/assets/images/summit/speaker/Raymond Yuan.png"
									alt=""
								/>
							</div>
							<div class="f_231_5_item_name">
								<img
									src="https://res.metaera.hk/resources/assets/images/forum/12.png"
									alt=""
								/>
								<span> Raymond Yuan </span>
							</div>
							<div class="f_231_5_item_content"> WSPN 创始人兼首席执行官 </div>
						</div>

						<div class="f_231_5_item f_231_5_item_1">
							<div class="f_231_5_item_img">
								<img
									src="https://res.metaera.hk/resources/assets/images/summit/speaker/Alex Yang.png"
									alt=""
								/>
							</div>
							<div class="f_231_5_item_name">
								<img
									src="https://res.metaera.hk/resources/assets/images/forum/12.png"
									alt=""
								/>
								<span> Alex Yang </span>
							</div>
							<div class="f_231_5_item_content">
								香港中国金融协会副主席<br />
								Volmart Inc 创始人兼首席执行官
							</div>
						</div>

						<div class="f_231_5_item f_231_5_item_1">
							<div class="f_231_5_item_img">
								<img
									src="https://res.metaera.hk/resources/assets/images/summit/speaker/周成岗.png"
									alt=""
								/>
							</div>
							<div class="f_231_5_item_name">
								<img
									src="https://res.metaera.hk/resources/assets/images/forum/12.png"
									alt=""
								/>
								<span> 周成岗 </span>
							</div>
							<div class="f_231_5_item_content">
								中国太保投资管理（香港）有限公司的行政总裁及执行董事
							</div>
						</div>

						<div class="f_231_5_item f_231_5_item_1">
							<div class="f_231_5_item_img">
								<img
									src="https://res.metaera.hk/resources/assets/images/summit/speaker/朱皓康.png"
									alt=""
								/>
							</div>
							<div class="f_231_5_item_name">
								<img
									src="https://res.metaera.hk/resources/assets/images/forum/12.png"
									alt=""
								/>
								<span> 朱皓康 </span>
							</div>
							<div class="f_231_5_item_content">
								华夏基金（香港）数字资产管理主管和家族财富管理主管<br />
								亚洲RWA工作组创始人<br />
								香港数码港创业顾问委员会委员及 Web3 投资孵化顾问
							</div>
						</div>

						<div class="f_231_5_item f_231_5_item_1">
							<div class="f_231_5_item_img">
								<img
									src="https://res.metaera.hk/resources/assets/images/summit/speaker/Victor Qian.png"
									alt=""
								/>
							</div>
							<div class="f_231_5_item_name">
								<img
									src="https://res.metaera.hk/resources/assets/images/forum/12.png"
									alt=""
								/>
								<span> Victor Qian </span>
							</div>
							<div class="f_231_5_item_content">
								Galaxy Digital 全球市场副总裁
							</div>
						</div>

						<div class="f_231_5_item f_231_5_item_1">
							<div class="f_231_5_item_img">
								<img
									src="https://res.metaera.hk/resources/assets/images/summit/speaker/Jessica Yang.png"
									alt=""
								/>
							</div>
							<div class="f_231_5_item_name">
								<img
									src="https://res.metaera.hk/resources/assets/images/forum/12.png"
									alt=""
								/>
								<span> Jessica Yang </span>
							</div>
							<div class="f_231_5_item_content"> MetaEra 首席执行官 </div>
						</div>

						<div class="f_231_5_item f_231_5_item_1">
							<div class="f_231_5_item_img">
								<img
									src="https://res.metaera.hk/resources/assets/images/summit/speaker/黄俊瑯.png"
									alt=""
								/>
							</div>
							<div class="f_231_5_item_name">
								<img
									src="https://res.metaera.hk/resources/assets/images/forum/12.png"
									alt=""
								/>
								<span> 黄俊瑯 </span>
							</div>
							<div class="f_231_5_item_content"> Web3Labs 首席执行官 </div>
						</div>

						<div class="f_231_5_item f_231_5_item_1">
							<div class="f_231_5_item_img">
								<img
									src="https://res.metaera.hk/resources/assets/images/summit/speaker/George Cao.png"
									alt=""
								/>
							</div>
							<div class="f_231_5_item_name">
								<img
									src="https://res.metaera.hk/resources/assets/images/forum/12.png"
									alt=""
								/>
								<span> George Cao </span>
							</div>
							<div class="f_231_5_item_content"> Maxsquare 对冲基金创始人 </div>
						</div>

						<div class="f_231_5_item f_231_5_item_1">
							<div class="f_231_5_item_img">
								<img
									src="https://res.metaera.hk/resources/assets/images/summit/speaker/Rita Liu.png"
									alt=""
								/>
							</div>
							<div class="f_231_5_item_name">
								<img
									src="https://res.metaera.hk/resources/assets/images/forum/12.png"
									alt=""
								/>
								<span> Rita Liu </span>
							</div>
							<div class="f_231_5_item_content">
								RD Technologies 首席执行官
							</div>
						</div>

						<div class="f_231_5_item f_231_5_item_1">
							<div class="f_231_5_item_img">
								<img
									src="https://res.metaera.hk/resources/assets/images/summit/speaker/Zack Yang.png"
									alt=""
								/>
							</div>
							<div class="f_231_5_item_name">
								<img
									src="https://res.metaera.hk/resources/assets/images/forum/12.png"
									alt=""
								/>
								<span> Zack Yang </span>
							</div>
							<div class="f_231_5_item_content"> FOMO Pay 联合创始人 </div>
						</div>

						<div class="f_231_5_item f_231_5_item_1">
							<div class="f_231_5_item_img">
								<img
									src="https://res.metaera.hk/resources/assets/images/summit/speaker/Michael Zhao.png"
									alt=""
								/>
							</div>
							<div class="f_231_5_item_name">
								<img
									src="https://res.metaera.hk/resources/assets/images/forum/12.png"
									alt=""
								/>
								<span> Michael Zhao </span>
							</div>
							<div class="f_231_5_item_content"> Klickl 首席执行官 </div>
						</div>

						<div class="f_231_5_item f_231_5_item_1">
							<div class="f_231_5_item_img">
								<img
									src="https://res.metaera.hk/resources/assets/images/summit/speaker/黄德明.png"
									alt=""
								/>
							</div>
							<div class="f_231_5_item_name">
								<img
									src="https://res.metaera.hk/resources/assets/images/forum/12.png"
									alt=""
								/>
								<span> 黄德明</span>
							</div>
							<div class="f_231_5_item_content">
								AlloyX (Hong Kong) Limited 董事总经理
							</div>
						</div>

						<div class="f_231_5_item f_231_5_item_1">
							<div class="f_231_5_item_img">
								<img
									src="https://res.metaera.hk/resources/assets/images/summit/speaker/Jackee Wong.png"
									alt=""
								/>
							</div>
							<div class="f_231_5_item_name">
								<img
									src="https://res.metaera.hk/resources/assets/images/forum/12.png"
									alt=""
								/>
								<span> Jackee Wong </span>
							</div>
							<div class="f_231_5_item_content"> RedotPay 环球市场总监 </div>
						</div>

						<div class="f_231_5_item f_231_5_item_1">
							<div class="f_231_5_item_img">
								<img
									src="https://res.metaera.hk/resources/assets/images/summit/speaker/刘家良.png"
									alt=""
								/>
							</div>
							<div class="f_231_5_item_name">
								<img
									src="https://res.metaera.hk/resources/assets/images/forum/12.png"
									alt=""
								/>
								<span> 刘家良 </span>
							</div>
							<div class="f_231_5_item_content">
								鲲 KUN 创始人兼首席执行官
							</div>
						</div>

						<div class="f_231_5_item f_231_5_item_1">
							<div class="f_231_5_item_img">
								<img
									src="https://res.metaera.hk/resources/assets/images/summit/speaker/Henry Zhang.png"
									alt=""
								/>
							</div>
							<div class="f_231_5_item_name">
								<img
									src="https://res.metaera.hk/resources/assets/images/forum/12.png"
									alt=""
								/>
								<span> Henry Zhang </span>
							</div>
							<div class="f_231_5_item_content">
								DigiFT 创始人兼首席执行官
							</div>
						</div>

						<div class="f_231_5_item f_231_5_item_1">
							<div class="f_231_5_item_img">
								<img
									src="https://res.metaera.hk/resources/assets/images/summit/speaker/Vicky Wang.png"
									alt=""
								/>
							</div>
							<div class="f_231_5_item_name">
								<img
									src="https://res.metaera.hk/resources/assets/images/forum/12.png"
									alt=""
								/>
								<span> Vicky Wang </span>
							</div>
							<div class="f_231_5_item_content">
								Amber Premium 首席市场总监兼数字财富管理负责人
							</div>
						</div>

						<div class="f_231_5_item f_231_5_item_1">
							<div class="f_231_5_item_img">
								<img
									src="https://res.metaera.hk/resources/assets/images/summit/speaker/Sarah Ye.png"
									alt=""
								/>
							</div>
							<div class="f_231_5_item_name">
								<img
									src="https://res.metaera.hk/resources/assets/images/forum/12.png"
									alt=""
								/>
								<span> Sarah Ye </span>
							</div>
							<div class="f_231_5_item_content">
								RigSec 联合创始人兼首席执行官
							</div>
						</div>

						<div class="f_231_5_item f_231_5_item_1">
							<div class="f_231_5_item_img">
								<img
									src="https://res.metaera.hk/resources/assets/images/summit/speaker/Vito Zheng.png"
									alt=""
								/>
							</div>
							<div class="f_231_5_item_name">
								<img
									src="https://res.metaera.hk/resources/assets/images/forum/12.png"
									alt=""
								/>
								<span> Vito Zheng </span>
							</div>
							<div class="f_231_5_item_content"> MetaEra 创始人 </div>
						</div>
						<div class="f_231_5_item f_231_5_item_1">
							<div class="f_231_5_item_img">
								<img
									src="https://res.metaera.hk/resources/assets/images/summit/speaker/Jordan Liu.png"
									alt=""
								/>
							</div>
							<div class="f_231_5_item_name">
								<img
									src="https://res.metaera.hk/resources/assets/images/forum/12.png"
									alt=""
								/>
								<span> Jordan Liu </span>
							</div>
							<div class="f_231_5_item_content"> Alsa 联合创始人 </div>
						</div>

						<div class="f_231_5_item f_231_5_item_1">
							<div class="f_231_5_item_img">
								<img
									src="https://res.metaera.hk/resources/assets/images/summit/speaker/Michael Yung.png"
									alt=""
								/>
							</div>
							<div class="f_231_5_item_name">
								<img
									src="https://res.metaera.hk/resources/assets/images/forum/12.png"
									alt=""
								/>
								<span> Michael Yung </span>
							</div>
							<div class="f_231_5_item_content"> Google Cloud 战略顾问 </div>
						</div>
						<div class="f_231_5_item f_231_5_item_1">
							<div class="f_231_5_item_img">
								<img
									src="https://res.metaera.hk/resources/assets/images/summit/speaker/Keywolf Zhong.png"
									alt=""
								/>
							</div>
							<div class="f_231_5_item_name">
								<img
									src="https://res.metaera.hk/resources/assets/images/forum/12.png"
									alt=""
								/>
								<span> Keywolf Zhong </span>
							</div>
							<div class="f_231_5_item_content">
								SlowMist 合伙人兼首席产品官
							</div>
						</div>
						<div class="f_231_5_item f_231_5_item_1">
							<div class="f_231_5_item_img">
								<img
									src="https://res.metaera.hk/resources/assets/images/summit/speaker/Ramkumar Subramanian.png"
									alt=""
								/>
							</div>
							<div class="f_231_5_item_name">
								<img
									src="https://res.metaera.hk/resources/assets/images/forum/12.png"
									alt=""
								/>
								<span> Ramkumar Subramanian </span>
							</div>
							<div class="f_231_5_item_content"> OpenLedger 创始人 </div>
						</div>
						<div class="f_231_5_item f_231_5_item_1">
							<div class="f_231_5_item_img">
								<img
									src="https://res.metaera.hk/resources/assets/images/summit/speaker/Ryan Chow.png"
									alt=""
								/>
							</div>
							<div class="f_231_5_item_name">
								<img
									src="https://res.metaera.hk/resources/assets/images/forum/12.png"
									alt=""
								/>
								<span> Ryan Chow </span>
							</div>
							<div class="f_231_5_item_content"> Solv Protocol 创始人 </div>
						</div>
						<div class="f_231_5_item f_231_5_item_1">
							<div class="f_231_5_item_img">
								<img
									src="https://res.metaera.hk/resources/assets/images/summit/speaker/Lin Yang.png"
									alt=""
								/>
							</div>
							<div class="f_231_5_item_name">
								<img
									src="https://res.metaera.hk/resources/assets/images/forum/12.png"
									alt=""
								/>
								<span> Lin Yang </span>
							</div>
							<div class="f_231_5_item_content">
								Aquarius Capital 创始合伙人
							</div>
						</div>
						<div class="f_231_5_item f_231_5_item_1">
							<div class="f_231_5_item_img">
								<img
									src="https://res.metaera.hk/resources/assets/images/summit/speaker/Anthony Ng.png"
									alt=""
								/>
							</div>
							<div class="f_231_5_item_name">
								<img
									src="https://res.metaera.hk/resources/assets/images/forum/12.png"
									alt=""
								/>
								<span> Anthony Ng </span>
							</div>
							<div class="f_231_5_item_content">
								BitFi 联合创始人兼生态负责人
							</div>
						</div>
						<div class="f_231_5_item f_231_5_item_1">
							<div class="f_231_5_item_img">
								<img
									src="https://res.metaera.hk/resources/assets/images/summit/speaker/Andrew Sato.png"
									alt=""
								/>
							</div>
							<div class="f_231_5_item_name">
								<img
									src="https://res.metaera.hk/resources/assets/images/forum/12.png"
									alt=""
								/>
								<span> Andrew Sato </span>
							</div>
							<div class="f_231_5_item_content">
								PoPP & InnoAlpha 联合创始人
							</div>
						</div>
						<div class="f_231_5_item f_231_5_item_1">
							<div class="f_231_5_item_img">
								<img
									src="https://res.metaera.hk/resources/assets/images/summit/speaker/Pete Chen.png"
									alt=""
								/>
							</div>
							<div class="f_231_5_item_name">
								<img
									src="https://res.metaera.hk/resources/assets/images/forum/12.png"
									alt=""
								/>
								<span> Pete Chen </span>
							</div>
							<div class="f_231_5_item_content"> Takara Lend 联合创始人 </div>
						</div>

						<div class="f_231_5_item f_231_5_item_1">
							<div class="f_231_5_item_img">
								<img
									src="https://res.metaera.hk/resources/assets/images/summit/speaker/Charles Kang.png"
									alt=""
								/>
							</div>
							<div class="f_231_5_item_name">
								<img
									src="https://res.metaera.hk/resources/assets/images/forum/12.png"
									alt=""
								/>
								<span> Charles Kang </span>
							</div>
							<div class="f_231_5_item_content"> StakeStone 创始人 </div>
						</div>
					</div>
				</div>
				<div class="f_next" @click="next(9)">
					<img
						src="https://res.metaera.hk/resources/assets/images/forum/2.png"
						alt=""
					/>
				</div>
			</div>

			<div class="f_partners f_item">
				<div class="f_item_header_4 f_item_header_4_1">
					<div class="f_item_header_3"> 合作伙伴</div>
					<div class="f_item_header_2"> </div>
				</div>

				<div class="f_item_header_4">
					<div class="f_item_header_3_1"> 主办</div>
					<div class="f_item_header_2_1"> </div>
				</div>
				<div class="f_partners_body">
					<img
						v-for="(item, index) in partners3"
						:key="index"
						class="f_partners_body_img pointer"
						:src="item.pic"
						@click="showUrl(item.link)"
					/>
				</div>

				<div class="f_item_header_4">
					<div class="f_item_header_3_1"> 联合主办</div>
					<div class="f_item_header_2_1"> </div>
				</div>
				<div class="f_partners_body">
					<img
						v-for="(item, index) in partners2"
						:key="index"
						class="f_partners_body_img pointer"
						:src="item.pic"
						@click="showUrl(item.link)"
					/>
				</div>
				<div class="f_item_header_4">
					<div class="f_item_header_3_1"> 协办</div>
					<div class="f_item_header_2_1"> </div>
				</div>
				<div class="f_partners_body">
					<img
						v-for="(item, index) in partners"
						:key="index"
						class="f_partners_body_img pointer"
						:src="item.pic"
						:style="{ height: item.height + 'px' }"
						@click="showUrl(item.link)"
					/>
				</div>
				<div class="f_item_header_4">
					<div class="f_item_header_3_1"> 赞助商</div>
					<div class="f_item_header_2_1"> </div>
				</div>
				<div class="f_item_header_3_1_1">白金赞助</div>
				<div class="f_partners_body">
					<img
						v-for="(item, index) in payPartners"
						:key="index"
						class="f_partners_body_img pointer"
						:src="item.pic"
						@click="showUrl(item.link)"
					/>
				</div>
				<div class="f_item_header_3_1_1">高级赞助</div>

				<div class="f_partners_body">
					<img
						v-for="(item, index) in payPartners1"
						:key="index"
						class="f_partners_body_img pointer"
						:src="item.pic"
						@click="showUrl(item.link)"
					/>
				</div>

				<div class="f_item_header_3_1_1">媒体合作伙伴</div>

				<div class="f_partners_body">
					<img
						v-for="(item, index) in partners4"
						:key="index"
						class="f_partners_body_img pointer"
						:src="item.pic"
						@click="showUrl(item.link)"
					/>
				</div>

				<div class="f_next" @click="next(6)">
					<img
						src="https://res.metaera.hk/resources/assets/images/forum/2.png"
						alt=""
					/>
				</div>
			</div>

			<div class="f_pic f_item">
				<div class="f_add_content">
					<div class="f_pic_content_left">
						<div class="f_pic_content_left_line"></div>
						<div class="f_pic_content_left_pic1"> HONGKONG </div>
						<div class="f_pic_content_left_pic2">
							香港湾仔博览道1号香港会议展览中心(新翼入口)，5楼 5BCDE 馆，会场1
						</div>
						<div class="f_pic_content_left_pic3">
							Stage1, Hall5BCDE, Hong Kong Convention and Exhibition
							Centre(HKCEC) , 1 Expo Drive, Wan Chai, Hong Kong (Entrance to New
							wing)
						</div>
					</div>
					<div class="f_pic_content_right">
						<img
							src="https://res.metaera.hk/resources/assets/images/summit/output-4@3x.png"
							alt=""
						/>
					</div>
				</div>
			</div>

			<div class="f_add f_item">
				<div class="f_item_header_4">
					<div class="f_item_header_3"> 往期回顾</div>
					<div class="f_item_header_2"> </div>
				</div>
				<div class="f_back_content">
					<div class="f_back_content_left">
						<img
							class="pointer"
							src="https://res.metaera.hk/resources/assets/images/summit/组 192590@3x.png"
							alt=""
							@click="showUrl('https://metaera.hk/CryptoFi2024')"
						/>
					</div>
					<div class="f_back_content_right">
						<img
							class="pointer"
							src="https://res.metaera.hk/resources/assets/images/summit/组 192591@3x.png"
							alt=""
							@click="showUrl('https://metaera.hk/CryptoFi2023')"
						/>
					</div>
				</div>
			</div>

			<bottom></bottom>
		</div>
	</div>
</template>

<script>
import headerNav from "./headern.vue";
import Vue from "vue";
import bottom from "./pdbottom.vue";

export const newBus = new Vue();
export default {
	name: "",
	components: { headerNav, bottom },
	data() {
		return {
			osafai: false,
			tabs: ["页面一", "页面二", "页面三"], // 导航栏的选项
			activeTab: 0, // 当前激活的导航栏索引
			scrollTimeout: null, // 用于检测滚动停止
			videoUrl: "https://res.metaera.hk/resources/assets/images/forum/2024.mp4",
			posterUrl:
				"https://res.metaera.hk/resources/assets/images/forum/44_1.png",
			showOverlay: true,

			videoUrlTop:
				"https://res.metaera.hk/resources/assets/images/summit/METAERA 加密金融論壇V34.0 (加上陳茂波司長+NANO LOGO).mp4",
			posterUrlTop:
				"https://res.metaera.hk/resources/assets/images/summit/20250328-190602 (1).png",
			showOverlayTop: true,

			partners: [
				{
					pic: "https://res.metaera.hk/resources/assets/images/summit/3.0协会@1x.png",
					link: "",
					height: 60,
				},
				{
					pic: "https://res.metaera.hk/resources/assets/images/summit/金融协会@1x.png",
					link: "",
					height: 60,
				},
				{
					pic: "https://res.metaera.hk/resources/assets/images/summit/img_v3_02kf_4f63a2a5-c9ee-488b-a5fe-d3c9f77f4fhu.webp",
					link: "",
					height: 60,
				},
			],
			partners2: [
				{
					pic: "https://res.metaera.hk/resources/assets/images/summit/20250318-212904.png",
					link: "",
					height: 60,
				},
				{
					pic: "https://res.metaera.hk/resources/assets/images/summit/20250310-203843.png",
					link: "",
					height: 60,
				},
			],
			partners3: [
				{
					pic: "https://res.metaera.hk/resources/assets/images/summit/CRYPTOFI ME LOGO.png",
					link: "",
					height: 60,
				},
			],
			partners4: [
				{
					pic: "https://res.metaera.hk/resources/assets/images/summit/m1.png",
					link: "",
					height: 60,
				},
				{
					pic: "https://res.metaera.hk/resources/assets/images/summit/m2.png",
					link: "",
					height: 60,
				},
				{
					pic: "https://res.metaera.hk/resources/assets/images/summit/m3.png",
					link: "",
					height: 60,
				},
				{
					pic: "https://res.metaera.hk/resources/assets/images/summit/m4.png",
					link: "",
					height: 60,
				},
				{
					pic: "https://res.metaera.hk/resources/assets/images/summit/m5.png",
					link: "",
					height: 60,
				},
				{
					pic: "https://res.metaera.hk/resources/assets/images/summit/m6.png",
					link: "",
					height: 60,
				},
				{
					pic: "https://res.metaera.hk/resources/assets/images/summit/m7.png",
					link: "",
					height: 60,
				},
				{
					pic: "https://res.metaera.hk/resources/assets/images/summit/blocktempo.png",
					link: "",
					height: 60,
				},
				{
					pic: "https://res.metaera.hk/resources/assets/images/summit/techub._LOGO_1.png",
					link: "",
					height: 60,
				},
				{
					pic: "https://res.metaera.hk/resources/assets/images/summit/m8.png",
					link: "",
					height: 60,
				},
			],

			payPartners: [
				{
					pic: "https://res.metaera.hk/resources/assets/images/summit/logo/2.png",
					link: "",
					height: 60,
				},
				{
					pic: "https://res.metaera.hk/resources/assets/images/summit/原-横.png",
					link: "",
					height: 60,
				},
				{
					pic: "https://res.metaera.hk/resources/assets/images/summit/image (2).png",
					link: "",
					height: 60,
				},
				{
					pic: "https://res.metaera.hk/resources/assets/images/summit/logo/5.png",
					link: "",
					height: 60,
				},
				{
					pic: "https://res.metaera.hk/resources/assets/images/summit/20250310-213727.png",
					link: "",
					height: 60,
				},
				{
					pic: "https://res.metaera.hk/resources/assets/images/summit/20250319-174819.png",
					link: "",
					height: 60,
				},
				{
					pic: "https://res.metaera.hk/resources/assets/images/summit/ChinaAMC_logo_TC.png",
					link: "",
					height: 60,
				},
			],
			payPartners1: [
				{
					pic: "https://res.metaera.hk/resources/assets/images/summit/logo/1.png",
					link: "",
					height: 60,
				},
				{
					pic: "https://res.metaera.hk/resources/assets/images/summit/20250310-213731.png",
					link: "",
					height: 60,
				},
				{
					pic: "https://res.metaera.hk/resources/assets/images/summit/WSPN logo_black.png",
					link: "",
					height: 60,
				},
				{
					pic: "https://res.metaera.hk/resources/assets/images/summit/fomopay.png",
					link: "",
					height: 60,
				},
				{
					pic: "https://res.metaera.hk/resources/assets/images/summit/纯英文.jpg",
					link: "",
					height: 60,
				},
				{
					pic: "https://res.metaera.hk/resources/assets/images/summit/CHAIN-常规.png",
					link: "",
					height: 60,
				},
				{
					pic: "https://res.metaera.hk/resources/assets/images/summit/RD Technologies - Brand Logo - RGB - Dark (Light BG).png",
					link: "",
					height: 60,
				},
				{
					pic: "https://res.metaera.hk/resources/assets/images/summit/Klickl_Black.svg",
					link: "",
					height: 60,
				},
				{
					pic: "https://res.metaera.hk/resources/assets/images/summit/logo/3.png",
					link: "",
					height: 60,
				},
				{
					pic: "https://res.metaera.hk/resources/assets/images/summit/WechatIMG313.jpg",
					link: "",
					height: 60,
				},
				{
					pic: "https://res.metaera.hk/resources/assets/images/summit/20250312-165932.png",
					link: "",
					height: 60,
				},
				{
					pic: "https://res.metaera.hk/resources/assets/images/summit/alsa.jpg",
					link: "",
					height: 60,
				},
				{
					pic: "https://res.metaera.hk/resources/assets/images/summit/雨川.png",
					link: "",
					height: 60,
				},
				{
					pic: "https://res.metaera.hk/resources/assets/images/summit/logo/4.png",
					link: "",
					height: 60,
				},
				{
					pic: "https://res.metaera.hk/resources/assets/images/summit/Black icon Black text.png",
					link: "",
					height: 60,
				},
				{
					pic: "https://res.metaera.hk/resources/assets/images/summit/sunpump logo.png",
					link: "",
					height: 60,
				},
				{
					pic: "https://res.metaera.hk/resources/assets/images/summit/logo/20250403-172412.png",
					link: "",
					height: 60,
				},
			],
		};
	},
	created() {},
	mounted() {
		this.selHKLanguage();
	},

	metaInfo() {
		let descStr = "香港加密金融论坛  CryptoFi Forum HongKong";
		let title = "香港加密金融论坛  CryptoFi Forum HongKong";
		let keyword =
			"CFF，加密金融论坛，香港加密金融论坛，CryptoFi Forum ，CryptoFi Forum HongKong，金融论坛，加密金融，";
		return {
			title: title,
			meta: [
				{
					name: "keywords",
					content: keyword,
				},
				{ name: "description", content: descStr },
				{
					property: "og:description",
					content: descStr,
				},
				{
					property: "og:type",
					content: "article",
				},
				{
					property: "og:site_name",
					content: title,
				},
				{ property: "og:title", content: title },
				{
					property: "twitter:description",
					content: descStr,
				},
				{
					property: "og:image",
					content: "https://res.metaera.hk/resources/logo_share.png",
				},
				{
					property: "twitter:image",
					content: "https://res.metaera.hk/resources/logo_share.png",
				},
				{ property: "twitter:title", content: title },
			],
		};
	},
	methods: {
		sendEmail(url) {
			const email = url;
			const subject = "";
			const body = "";
			window.location.href = `mailto:${email}?subject=${encodeURIComponent(
				subject
			)}&body=${encodeURIComponent(body)}`;
		},
		selHKLanguage() {
			const index = 0;
			this.showSel = 0;
			this.localLanguage = index;
			sessionStorage.setItem("localLanguageHK", "简体中文");
			if (this.localLanguage == 0) {
				this.$t2s(document.body);
			} else {
				this.$e2s(document.body, 0);
			}
			// 通知发送
			newBus.$emit("global-notification", {
				localLanguage: this.localLanguage,
			});
		},
		next(num) {},
		top() {
			this.next(-1);
		},
		playVideo() {
			const video = this.$refs.videoPlayer;
			video.play();
		},
		playVideoYTB() {
			window.open("https://www.youtube.com/watch?v=XRiJo-XQ1Ik", "");
		},
		hideOverlay() {
			this.showOverlay = false;
		},
		playVideoTop() {
			this.$refs.videoPlayer_top.play();
		},
		playVideoYTBTop() {
			window.open("https://www.youtube.com/watch?v=JE0NHVrHnX8", "");
		},
		hideOverlayTop() {
			this.showOverlayTop = false;
		},
		handleCanPlay() {
			// 尝试自动播放
			this.$refs.videoPlayer.play().catch((error) => {
				console.log("Autoplay failed:", error);
			});
		},
		handleCanPlayTop() {
			// 尝试自动播放
			this.$refs.videoPlayer_top.play().catch((error) => {
				console.log("Autoplay failed:", error);
			});
		},

		buyBtnClick(url) {
			window.open(url, "");
			this.$addLog("CryptoFi_button_click_buy", {});
		},
		showUrl(url) {
			window.open(url, "");
		},
		mounted() {
			// 确保视频在加载完毕后自动播放
			this.$refs.videoPlayer.play().catch((error) => {
				console.log("Autoplay failed:", error);
			});
			this.$refs.videoPlayer_top.play().catch((error) => {
				console.log("Autoplay failed:", error);
			});
		},
	},
};
</script>

<!-- f_indicator -->
<style scoped>
.f_indicator_sub {
	width: 51px;
	height: 40px;
	opacity: 1;
	position: absolute;
	bottom: 10px;
	right: 10%;
	z-index: 999;
	display: block;
	align-content: center;
	align-items: center;
	text-align: center;
	display: none;
}
.f_indicator_sub_num {
	margin-left: 17px;
	font-size: 20px;
	font-weight: 400;
	line-height: 20px;
	display: flex;
	align-items: center;
	text-transform: uppercase;
	letter-spacing: 0em;
	color: #ffffff;
	height: 20px;
	align-content: center;
	align-items: center;
	text-align: center;
}
.f_indicator_sub_line {
	margin-top: 10px;
	margin-left: 17px;
	width: 17px;
	height: 2px;
	border-radius: 43px;
	opacity: 1;
	/* 纯白 */
	background: #ffffff;
}
.f_indicator_sub_img {
	margin-top: 20px;
	width: 51px;
	height: auto;
}
.f_indicator {
	text-align: center;
	align-content: center;
	width: 80px;
	height: 180px;
	opacity: 1;
	z-index: 999;
	position: fixed;
	top: calc(50vh - 110px);
	right: 20px;
	z-index: 999;
	/* background: orange; */
	display: flex;
	display: none;
}

.f_indicator_left {
	width: 2px;
	height: 100%;
	display: flex;
}

.f_indicator_line {
	width: 2px;
	height: 100%;
	opacity: 0.5;
	/* 纯白 */
	background: #ffffff;
}
.f_indicator_right {
	margin-top: 20px;
	width: 50px;
}

.f_indicator_item {
	height: 30px;
	width: 100%;
	display: flex;

	position: relative; /* 父容器需要相对定位，作为子元素的定位参考 */
}
.f_indicator_triangle {
	position: relative; /* 子元素1相对自己的默认位置 */
	width: 8px;
	height: 8px;
	color: white;
	display: flex;
	justify-content: center;
	top: 0px; /* 相对自身位置向下移动 */
	left: 0px; /* 相对自身位置向右移动 */
}
.f_indicator_triangle img {
	width: 100%;
	height: auto;
	object-fit: cover;
}
.f_indicator_pointer {
	opacity: 0.5;
	background: #e0eaff;
	width: 6px;
	height: 6px;
	border-radius: 3px;

	position: absolute; /* 子元素1相对自己的默认位置 */
	display: flex;
	justify-content: center;
	top: 0px; /* 相对自身位置向下移动 */
	right: 0px; /* 相对自身位置向右移动 */
}

.f_indicator_pointer_sel {
	width: 20px;
	opacity: 1;
	background: #e0eaff;
}
@media screen and (max-width: 900px) {
	.f_indicator,
	.f_indicator_sub {
		display: none;
	}
}
</style>
<style scoped>
.pd_body {
	display: flex;
	flex-direction: column;
	overflow: hidden;
	/* height: 100vh; */
	overflow-y: auto; /* 可滚动 */
	scroll-behavior: smooth; /* 平滑滚动 */
}
.scroll-container {
	height: 100vh;
	overflow-y: scroll;
}
.js-copy {
	position: absolute;
	top: 0px;
	left: 0;
	width: calc(100vw);
	margin: 0 auto;
	background: #3d29aa;
}
</style>
<style scoped>
.f_next {
	position: absolute; /* 子元素设置为绝对定位 */
	bottom: 40px; /* 距离底部为 0 */
	width: 100%; /* 可选：设置宽度为父容器宽度 */
	text-align: center;
	display: block;
	display: none;
}
.f_next img {
	width: 32px !important;
}
.f_item {
	position: relative;
	width: 100%;
	/* min-height: calc(100vh - 80px); */
	height: auto;
	background-image: url("https://res.metaera.hk/resources/assets/images/forum/5000.png");
	background-size: cover; /* 背景图片缩放适配 */
	background-position: center; /* 背景图片居中 */
	background-repeat: no-repeat; /* 不重复背景图片 */
	text-align: center;
	padding-top: 0px;
}
@media screen and (max-width: 900px) {
	.f_item {
		min-height: auto;
		height: auto;
		padding-top: 10px;
	}
	.f_next {
		bottom: 70px; /* 距离底部为 0 */
		display: none;
	}
}
</style>
<!-- f_value -->
<style scoped>
.f_value {
	text-align: start;
	background: #7a64ff;
}

.f_value_top {
	margin-top: -80px;
	padding-top: 28px;
	margin-left: 5%;
	margin-right: 5%;
	border-radius: 20px;
	text-align: center;
	align-content: center;
	align-items: center;
	opacity: 1;
	background: #6429ff;
}
.f_item_header {
	text-align: center;
	align-content: center;
	align-items: center;
	display: inline-block;
	border-radius: 5px;
	width: 120px;
	opacity: 1;
	background: #ffffff;
}

.f_item_header_1 {
	text-align: center;
	align-content: center;
	align-items: center;
	font-size: 24px;
	height: 40px;
	line-height: 40px;
	font-weight: bold;
	text-transform: uppercase;
	letter-spacing: 0em;
	font-variation-settings: "opsz" auto;
	color: #7a64ff;
}

.f_item_header_2 {
	/* 纯白 */
	background: #8bffae;
	height: 4px;
	width: 27px;
	text-align: center;
	align-content: center;
	display: inline-block;
}

.f_item_header_2_1 {
	background: #6429ff;
	margin-top: -40px;
	height: 20px;
	width: 60px;
	text-align: center;
	align-content: center;
	display: inline-block;
	border-radius: 10px;
}

.f_item_header_3 {
	font-size: 44px;
	font-weight: bold;
	line-height: normal;
	text-transform: uppercase;
	letter-spacing: 0em;
	font-variation-settings: "opsz" auto;
	/* 纯白 */
	color: #ffffff;
}

.f_item_header_3_1 {
	font-size: 34px;
	font-weight: bold;
	line-height: normal;
	text-transform: uppercase;
	letter-spacing: 0em;
	font-variation-settings: "opsz" auto;
	/* 纯白 */
	color: #ffffff;
}

.f_item_header_4 {
	margin-top: 60px;
	text-align: center;
	align-content: center;
	align-items: center;
	opacity: 1;
}
.f_item_body {
	margin-top: 20px;
	margin-left: 10%;
	margin-right: 10%;
	display: flex;
}
.f_item_body1 {
	margin-top: 2%;
}
.f_item_body2 {
	margin-top: 2%;
}

.f_item_body_block {
	position: absolute;
	width: 70px;
	height: 120px;
	opacity: 1;
	z-index: 1;
	background: #6429ff;
}

@media screen and (max-width: 900px) {
	.f_value {
		min-height: auto;
	}
	.f_item_body_block {
		width: 30px;
	}
	.f_item_body_block3 {
		height: 300px !important;
	}
	.f_item_header {
		margin-top: 20px;
	}
	.f_item_header_3 {
		font-size: 20px;
	}
	.f_item_header_3_1 {
		font-size: 16px;
	}
	.f_item_header_4_1 {
		margin-top: 30px;
	}
	.f_item_header_4 {
		margin-top: 30px;
	}
}
.f_item_body_block3 {
	height: 210px;
}

.f_item_body_block4 {
	height: 120px;
	width: 40px;
}

.f_item_body_block5 {
	height: 160px;
}

.f_item_body_block6 {
	height: 200px;
	position: absolute;
}
.f_item_body_block7 {
	width: 40px;
	height: 140px;
}

.f_item_body_block8 {
	width: 40px;
	height: 60px;
}
.f_item_body_text {
	padding-top: 30px;
	padding-bottom: 20px;
	margin-left: 40px;
	font-size: 24px;
	font-weight: 400;
	line-height: 2;
	letter-spacing: 0em;
	font-variation-settings: "opsz" auto;
	/* 纯白 */
	color: #ffffff;
}

.f_item_body_text_11 {
	padding-top: 20px;
	font-size: 20px;
}
@media screen and (max-width: 900px) {
	.f_item_body_text {
		font-size: 14px;
		margin-left: 20px;
		margin-right: 20px;
	}
	.f_item_body_text3,
	.f_item_body_text2,
	.f_item_body_text1 {
		font-size: 15px !important;
		margin-left: 20px !important;
		margin-right: 20px !important;
	}
	.f_item_body_block6 {
		height: 400px;
	}
}
.f_item_body_text1 {
	font-size: 20px;
	margin-left: 20px;
}

.f_item_body_text2 {
	margin-left: 20px;
	font-size: 20px;
}

.f_item_body_text3 {
	font-size: 26px;
	font-weight: 400;
	line-height: 44px;
	letter-spacing: 0em;
	font-variation-settings: "opsz" auto;
	/* 纯白 */
	color: #ffffff;
	margin-left: 10%;
	margin-right: 10%;
}

@media screen and (max-width: 900px) {
	.f_item_header_1 {
		font-size: 16px;
	}
	.f_item_header_2 {
		font-size: 20px;
	}
}
</style>
<!-- f_themes -->
<style scoped>
.f_themes {
	text-align: start;
	padding-top: 100px;
	/* min-height: calc(100vh - 80px); */
}
.f_themes_body {
	margin-top: 2%;
	margin-left: 10%;
	margin-right: 10%;
	display: flex;
	justify-content: space-between;
}

.f_themes_body_left,
.f_themes_body_right {
	width: 48%;
}

.f_themes_body_right_1 {
	display: grid;
	justify-content: space-between;
}

.f_themes_body_item {
	border-radius: 2px;
	opacity: 1;
	box-sizing: border-box;
	border: 2px solid #6454bb;
	padding: 20px;
	text-align: start;
	margin-bottom: 24px;
	min-height: 290px;
}
.f_themes_body_item_1 {
	min-height: auto;
}

.f_themes_body_item_line {
	opacity: 1;
	background: #8bffae;
	height: 4px;
	width: 40px;
}

.f_themes_body_item_name {
	margin-top: 10px;
	display: block;
	height: 60px;
}

.f_themes_body_item_name1 {
	margin-left: 10%;
	margin-top: 3%;
}

.f_themes_body_item_name_1 {
	margin-right: 10px;
	height: 30px;
}

.f_themes_body_item_name_1 span {
	padding-left: 6px;
	padding-right: 6px;
	font-size: clamp(16px, calc(100vw * 16 / 1280), 20px);
	font-weight: bold;
	line-height: normal;
	letter-spacing: 0em;
	font-variation-settings: "opsz" auto;
	/* 纯白 */
	color: #ffffff;
	opacity: 1;
	background: #6429ff;
}

.f_themes_body_item_name_11 {
	min-width: 140px;
}
.f_themes_body_item_name_12 {
	min-width: 190px;
}
.f_themes_body_item_name_2 {
	font-size: clamp(8px, calc(100vw * 16 / 1280), 20px);
	font-weight: 400;
	line-height: normal;
	letter-spacing: 0em;
	font-variation-settings: "opsz" auto;
	/* 纯白 */
	color: #ffffff;
}

.f_themes_body_item_title {
	margin-top: 20px;
	font-size: clamp(12px, calc(100vw * 14 / 1280), 20px);
	font-weight: 400;
	line-height: 30px;
	letter-spacing: 0em;
	font-variation-settings: "opsz" auto;
	/* 纯白 */
	color: #ffffff;
	min-height: clamp(8px, calc(100vw * 100 / 1280), 120px);
}

.f_themes_body_item_title_1 {
	min-height: 0px;
}

.f_themes_body_item_text_1 {
	min-height: 0px !important;
}
.f_themes_body_item_text {
	font-size: clamp(12px, calc(100vw * 16 / 1280), 18px);
	font-weight: 400;
	line-height: 1.5;
	letter-spacing: 0em;
	color: #ffffff;
	opacity: 0.6;
	min-height: clamp(60px, calc(100vw * 80 / 1280), 110px);
}
@media screen and (max-width: 900px) {
	.f_themes {
		margin-top: 40px;
		padding-top: 0px;
		min-height: auto;
	}

	.f_themes_body {
		display: block;
	}

	.f_themes_body_left,
	.f_themes_body_right {
		width: 100%;
	}
	.f_themes_body_item_name {
		margin-top: 10px;
		display: block;
		height: auto;
	}
	.f_themes_body_item_name_2 {
		font-size: 14px;
	}
	.f_themes_body_item_name_1 {
		height: auto;
	}
	.f_themes_body_item {
		min-height: 200px;
	}
}
</style>
<!-- f_home -->
<style scoped>
.f_home {
	align-content: flex-end;
	margin-top: 80px;
	background-image: url("https://res.metaera.hk/resources/assets/images/forum/64.png");
	display: flex;
	justify-content: space-between;
}

.f_home img {
	width: 100%;
	height: calc(100vh - 80px);
	object-fit: scale-down;
}

.f_home_1_img {
	width: 100%;
	height: auto !important;
	/* height: calc(100vh - 80px); */
	object-fit: contain;
}

.f_home_link {
	width: 120px;
	padding-left: 20px;
	padding-right: 20px;
	padding-top: 10px;
	padding-bottom: 10px;
	margin-top: 40px;
	display: flex;
	background: #502ad4;
	justify-content: space-between;
	border-radius: 6px;
}

.f_home_link img {
	width: 30px !important;
	height: 30px !important;
}

.f_home_5 {
	width: 50%;
	margin-right: 10%;
	/* margin-bottom: 25%; */
	/* margin-top: 20%; */
	z-index: 2;
	position: relative;
}

.f_home_1 {
	width: 30%;
	margin-left: 10%;
	/* margin-bottom: 25%; */
	/* margin-top: 20%; */
	position: relative;
	align-items: center;
	/* padding-top: 20%; */
	align-content: center;
}
.f_home_2 {
	width: 100%;
	margin-bottom: 50px;

	font-size: 24px;
	font-weight: 400;
	line-height: 24px;
	align-items: center;
	text-transform: uppercase;
	letter-spacing: 0em;
	font-variation-settings: "opsz" auto;
	/* 纯白 */
	color: #ffffff;
}
.f_home_3 {
	width: 16%;
	margin-left: 42%;
	margin-bottom: 120px;
}

@media screen and (max-width: 900px) {
	.f_home {
		margin-top: 50px;
		min-height: 80vh;
		background-image: url("https://res.metaera.hk/resources/assets/images/forum/50.png");
	}
	.f_home_5 {
		display: none;
	}
	.f_home_1 {
		width: 80%;
		margin-left: 10%;
		margin-bottom: 30%;
		padding-top: 20%;
	}
	.f_home_2 {
		width: 100%;
		margin-bottom: 20px;
		font-size: 14px;
	}
	.f_home_3 {
		width: 40%;
		margin-left: 30%;
		margin-bottom: 60px;
	}
}
</style>
<!-- f_about -->
<style scoped>
.f_about {
	text-align: start;
	margin-bottom: 0px;
	padding-bottom: 100px;
	background: #7a64ff;

	/* min-height: calc(100vh - 280px); */
}
.f_about_1 {
	margin-top: 10px;
	font-size: 30px;
	font-weight: bold;
	line-height: 30px;
	align-items: center;
	text-transform: uppercase;
	letter-spacing: 0em;
	font-variation-settings: "opsz" auto;
	color: #ffffff;
	z-index: 2;
	position: relative;
	margin-left: 30px;
}
.f_about_1_1 {
	margin-left: 20px;
}
.f_about_2 {
	margin-top: 20px;
	margin-left: calc(50vw - 21px);
	width: 42px;
	height: 2px;
	border-radius: 323px;
	opacity: 1;
	background: #ffffff;
}
.f_about_3 {
	margin-top: 0px;
}

.f_about_3 img {
	width: 10px;
	height: auto;
}
.f_about_4 {
	margin-top: 30px;
	width: 100%;

	display: flex;
	align-items: center;
	text-align: start;
	text-transform: uppercase;
	letter-spacing: 0em;
	color: #ffffff;
	line-height: 1.5;
	font-size: 20px;
	font-weight: 400;

	position: relative;
	margin-left: 30px;
	z-index: 2;
}
.f_about_5 {
	margin-top: 50px;
	display: flex;
	height: 300px;
	padding-left: 5%;
	padding-right: 5%;
	justify-content: space-between;
}

.f_about_6,
.f_about_7 {
	display: none;
}

.f_about_5_item {
	width: 20%;
	height: 100%;
	display: flex; /* 使用 flexbox 布局 */
	flex-direction: column;

	justify-content: space-between;
	text-align: center;
	border-radius: 2px;
	opacity: 1;
	box-sizing: border-box;
}

.f_about_5_item1 {
	margin-top: 40px;
}

.f_about_5_item1 img {
	width: clamp(30px, calc(100vw * 60 / 1280), 80px);
	height: auto;
}

.f_about_5_item2 {
	width: 100%;
	font-size: clamp(20px, calc(100vw * 30 / 1280), 40px);
	font-weight: 900;
	line-height: 40px;
	align-items: center;
	text-transform: uppercase;
	letter-spacing: 0em;
	font-variation-settings: "opsz" auto;
	color: #ffffff;
}
.f_about_5_item3 {
	margin-bottom: 40px;

	font-size: clamp(16px, calc(100vw * 18 / 1280), 20px);
	font-weight: 500;
	line-height: 20px;
	align-items: center;
	text-transform: uppercase;
	letter-spacing: 0em;
	font-variation-settings: "opsz" auto;
	color: #ffffff;
}
@media screen and (max-width: 900px) {
	.f_about {
		margin-bottom: 50px;
		padding-top: 0px;
	}
	.f_about_1 {
		margin-left: 10px;
	}
	.f_about_4 {
		margin-top: 10px;
		margin-left: 5%;
		width: 90%;
		font-size: 16px;
	}
	.f_about_5 {
		display: none;
	}

	.f_about_1_2 {
		margin-left: 10px;
	}

	.f_about_6,
	.f_about_7 {
		display: flex;
		margin-top: 20px;
		display: flex;
		height: 40%;
		padding-left: 15%;
		padding-right: 15%;
		justify-content: space-between;
	}

	.f_about_5_item {
		width: 40%;
		background: rgba(255, 255, 255, 0);
		box-sizing: border-box;
		border: 0px solid #ffffff;
	}
	.f_about_5_item1 {
		margin-top: 20px;
	}

	.f_about_5_item1 img {
		width: clamp(40px, calc(100vw * 50 / 414), 60px);
		height: auto;
	}
}
</style>

<!-- f_partners -->
<style scoped>
.f_partners {
	margin-bottom: 70px;
	text-align: start;
	background-image: url("https://res.metaera.hk/resources/assets/images/forum/64.png");
}

.f_item_header_3_1_1 {
	font-size: 30px;
	font-weight: bold;
	line-height: normal;
	text-transform: uppercase;
	letter-spacing: 0em;
	font-variation-settings: "opsz" auto;
	/* 纯白 */
	color: #ffffff;
	align-content: center;
	align-items: center;
	text-align: center;
	margin-top: 30px;
}

.f_partners_body {
	margin-left: 10%;
	margin-right: 10%;
	/* margin-top: 20px; */
	margin-bottom: 20px;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	gap: 50px; /* 设置图片间距 */
	/* background: #ffffff; */
	border-radius: 10px;
	padding-top: 20px;
	padding-bottom: 20px;
}

.f_partners_body_img {
	background: white;
	width: 10%; /* 宽度自适应 */
	height: 60px;
	object-fit: contain;
	padding: 10px;
	border-radius: 6px;
}

@media screen and (max-width: 900px) {
	.f_partners_body_img {
		width: 30%; /* 宽度自适应 */
		height: 40px !important;
	}
	.f_item_header_3_1_1 {
		font-size: 16px;
	}
}
</style>
<!-- f_25 -->
<style scoped>
.f_25 {
	/* min-height: calc(100vh - 80px); */
}
.f_25_1 {
	margin-top: 5%;
	height: 70%;
	margin-left: 10%;
	margin-right: 10%;
}

.f_25_1 img {
	width: 100%;
	height: 100%;
	object-fit: contain;
}
</style>

<!-- f_year_hedaer -->
<style scoped>
.f_year_hedaer {
	margin-top: 8vh;
	padding-top: 10px;
	padding-bottom: 10px;
	margin-left: 10%;
	margin-right: 10%;
}

.f_year_hedaer1 {
	margin-left: 20%;
}
@media screen and (max-width: 900px) {
	.f_partners {
		min-height: auto;
	}
	.f_year_hedaer1 {
		margin-left: 10%;
	}
	.f_25_1 {
		margin-top: 5%;
		height: auto;
		margin-left: 10%;
		margin-right: 10%;
	}
}
</style>

<!-- f_23 -->
<style scoped>
.f_23_1 {
	margin-top: 20px;
	display: inline-flex;
	text-align: center;
	z-index: 2;
	position: relative;
	margin-left: 30px;
}
.f_23_1_1 {
	margin-left: 20px;
}
.f_23_1_item {
	display: flex;
	align-items: center;
}

.f_23_1_item_app {
	display: flex;
}

.f_23_1 img {
	margin-right: 10px;
	width: 20px !important;
	height: 20px !important;
}

.f_23_1 span {
	font-size: 20px;
	font-weight: 400;
	line-height: 20px;
	display: flex;
	align-items: center;
	text-transform: uppercase;
	letter-spacing: 0em;
	font-variation-settings: "opsz" auto;
	color: #ffffff;
}
.f_23_2 {
	margin-top: 30px !important;
}
.f_23_5_list {
	width: 80%;
	margin-top: clamp(30px, calc(100vw * 60 / 1280), 80px);
	display: flex;
	padding-left: 10%;
	padding-right: 10%;
	display: flex;
	flex-wrap: wrap; /* 允许子元素换行 */
	gap: 50px; /* 子元素之间的间距 */
	justify-content: space-between;
}

.f_23_5_item {
	width: 30%;
}

.f_23_5_item img {
	width: 100%;
	height: auto;
	object-fit: contain;
}
@media screen and (max-width: 900px) {
	.f_23_1 {
		margin-left: 10px;
	}
	.f_23_1_item_app {
		display: none;
	}

	.f_23_1 img {
		margin-right: 10px;
		width: 14px !important;
		height: 14px !important;
	}

	.f_23_1 span {
		font-size: 14px;
	}

	.f_23_5_list {
		width: 90%;
		margin-top: 60px;
		display: flex;
		padding-left: 5%;
		padding-right: 5%;
		display: flex;
		flex-wrap: wrap; /* 允许子元素换行 */
		gap: 30px; /* 子元素之间的间距 */
		justify-content: space-between;
	}

	.f_23_5_item {
		width: 45%;
	}
	.f_23_5_item img {
		width: 100%;
		height: auto;
	}
}
</style>

<style scoped>
.f_add {
	text-align: start;
	padding-top: 20px;
	padding-bottom: 100px;
	background-image: url("https://res.metaera.hk/resources/assets/images/forum/64.png");
}

.f_add_content {
	display: flex;
	margin-top: 60px;
}

.f_add_content_left {
	margin-top: 0px;
	margin-left: 10%;
	margin-right: 0%;
	justify-content: space-between;
	display: inline-grid;
}

.f_add_content_left_span_1 {
	font-size: 24px;
	font-weight: bold;
	line-height: normal;
	letter-spacing: 0em;
	font-variation-settings: "opsz" auto;
	/* 纯白 */
	color: #ffffff;
}

.f_add_content_left_span_2 {
	margin-top: 20px;
	margin-bottom: 40px;
	font-size: 20px;
	font-weight: 400;
	line-height: normal;
	letter-spacing: 0em;
	font-variation-settings: "opsz" auto;
	/* 纯白 */
	color: #ffffff;
}
.f_add_content_right {
	margin-left: 5%;
	width: 60%;
	align-content: center;
	/* text-align: center; */
}

.f_add_content_right img {
	border-radius: 4px;
	width: 60%;
	height: auto;
}

@media screen and (max-width: 900px) {
	.f_add {
		padding-top: 0px;
		padding-bottom: 40px;
	}
	.f_add_content {
		display: block;
		margin-top: 20px;
	}
	.f_add_content_left {
		margin-left: 10%;
		margin-right: 10%;
		justify-content: space-between;
		display: inline-grid;
	}
	.f_add_content_right {
		margin-top: 30px;
		margin-left: 10%;
		width: 100%;
	}
	.f_add_content_right img {
		width: 80%;
		height: auto;
	}
	.f_add_content_left_span_1 {
		font-size: 20px;
	}
	.f_add_content_left_span_2 {
		font-size: 16px;
	}
}
</style>
<!-- f_video -->
<style scoped>
.f_video {
	background: #6429ff;
}

.f_video_bottom {
	margin-bottom: 100px;
}
.f_item_header_video {
	margin-top: 120px;
	margin-left: 10%;
	margin-right: 10%;

	font-family: D-DIN;
	font-size: 20px;
	font-weight: normal;
	line-height: 30px;
	text-align: center;
	letter-spacing: normal;
	/* 纯白 */
	color: #ffffff;
}

@media screen and (max-width: 900px) {
	.f_item_header_video {
		margin-top: 20px;
		font-size: 16px;
	}
	.f_video_bottom {
		margin-bottom: 0px;
	}
}
</style>

<style scoped>
.f_231,
.f_232,
.f_233 {
	text-align: start;
	/* min-height: calc(100vh - 80px); */
}

.f_232_prople {
	text-align: start;
	padding-bottom: 40px;
	background: #3c2aaa;
}

.f_23_5 {
	margin-bottom: 80px;
}

.f_231_1 {
	margin-top: 40px;
}
.f_231_2,
.f_232_2 {
	margin-top: 10px;
	display: block;
	text-align: center !important;
}
.f_232_2 {
	margin-top: 10px;
	margin-bottom: 20px;
}

.f_232_5 {
	margin-left: 20%;
	height: auto;
	width: 60%;
	height: auto;
	position: relative;
	display: block;
	margin-bottom: 0px;
	margin-top: 5%;
}

.f_232_5 video {
	height: 100%;
	width: 100%;
}

.video-overlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	cursor: pointer;
	border-radius: 10px;
}

.video-overlay-bg {
	width: 100%;
	height: 100%;
	object-fit: cover;
	cursor: pointer;
	border-radius: 10px;
}
.bottom-left-container {
	position: absolute;
	bottom: 25px; /* 你可以根据需要调整位置 */
	left: 0px; /* 你可以根据需要调整位置 */
	border-radius: 0px 10px 10px 0px;
	background: rgba(0, 0, 0, 0.6);
}
.info-box {
	display: flex;
	align-items: center;
}
.info-text {
	margin-left: 15px;
	margin-right: 15px;
	margin-top: 15px;
	margin-bottom: 15px;
	font-family: DINPro-Bold;
	font-size: 16px;
	font-weight: bold;
	line-height: 1.5;
	text-align: center;
	text-transform: uppercase;
	letter-spacing: 0em;

	font-variation-settings: "opsz" auto;
	font-feature-settings: "kern" on;
	color: #ffffff;
}
.info-icon {
	margin-right: 15px;
	width: auto;
	height: 20px;
}
.video-overlay-play {
	position: absolute;
	top: 50%;
	left: 50%;
	width: 70px;
	height: auto;
	transform: translate(-50%, -50%);
}
.video-overlay-play_top {
	position: absolute;
	top: 50%;
	left: 50%;
	width: 100px;
	height: auto;
	transform: translate(-50%, -50%);
}

.f_231_5 {
	margin-bottom: 40px;
}
.f_231_5_list {
	width: 90%;
	margin-top: 3%;
	display: flex;
	padding-left: 5%;
	padding-right: 5%;
	display: flex;
	flex-wrap: wrap; /* 允许子元素换行 */
	margin-bottom: 0px;
}

.f_231_5_item {
	width: 20%;
}

.f_231_5_item_1 {
	margin-left: 5%;
	margin-right: 5%;
	width: 15%;
	text-align: center;
}
.f_231_5_item_img {
	background-image: url("https://res.metaera.hk/resources/assets/images/summit/bejing.png");
	background-size: contain; /* 背景图片铺满容器 */
	background-position: center; /* 背景图片居中 */
	background-repeat: no-repeat; /* 防止背景图片重复 */
}
.f_231_5_item_img img {
	padding-left: 5%;
	padding-right: 5%;
	margin-top: 20px;
	object-fit: contain;
	aspect-ratio: 1 / 1; /* 宽高比设置为1:1 */
	width: 90%;
	border-radius: 6px;
}
.f_231_5_item_name {
	margin-top: 20px;
	align-content: center;
	text-align: start;
	align-items: center;
}
.f_231_5_item_name img {
	width: 10px;
	height: auto;
	object-fit: contain;
	margin-right: 6px;
}

.f_231_5_item_name span {
	font-size: clamp(12px, calc(100vw * 15 / 1280), 20px);
	font-weight: bold;
	line-height: 20px;
	align-items: center;
	text-transform: uppercase;
	letter-spacing: 0em;
	font-variation-settings: "opsz" auto;
	color: #ffffff;
}

.f_231_5_item_content {
	margin-top: 10px;
	font-size: clamp(8px, calc(100vw * 10 / 1280), 12px);
	font-weight: 400;
	line-height: 20px;
	text-align: start;
	text-transform: uppercase;
	letter-spacing: 0em;
	font-variation-settings: "opsz" auto;
	color: #ffffff;
}
@media screen and (max-width: 900px) {
	.f_232_prople {
		margin-bottom: 40px;
	}
	.f_231_5_list {
		width: 80%;
		margin-top: 15px;
		display: flex;
		padding-left: 10%;
		padding-right: 10%;
		justify-content: space-between;
		display: flex;
		flex-wrap: wrap; /* 允许子元素换行 */
		gap: 10px; /* 子元素之间的间距 */
		margin-bottom: 60px;
	}

	.f_231_5_item {
		width: 36%;
	}

	.f_231_5_item_img img {
		height: auto;
		width: 100%;
	}

	.f_231_5_item_name {
		margin-top: 10px;
	}

	.f_231_5_item_name img {
		width: 10px;
	}

	.f_232_2 {
		margin-bottom: 40px;
	}

	.f_231_5_item_name span {
		font-size: clamp(12px, calc(100vw * 15 / 1280), 20px);
		font-weight: bold;
		line-height: 20px;
		align-items: center;
		text-transform: uppercase;
		letter-spacing: 0em;
		font-variation-settings: "opsz" auto;
		color: #ffffff;
	}

	.f_231_5_item_content {
		margin-top: 10px;
		font-size: clamp(8px, calc(100vw * 10 / 1280), 12px);
		font-weight: 400;
		line-height: 20px;
		text-transform: uppercase;
		letter-spacing: 0em;
		font-variation-settings: "opsz" auto;
		color: #ffffff;
	}

	.f_232_5 {
		margin-left: 0%;
		height: auto;
		width: 80%;
		position: relative;
		display: inline-block;
		margin-bottom: 60px;
	}

	.f_232_5 video {
		width: 100%;
		height: auto;
	}

	.video-overlay {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		cursor: pointer;
		border-radius: 10px;
	}

	.video-overlay-bg {
		width: 100%;
		height: 100%;
		object-fit: cover;
		cursor: pointer;
		border-radius: 10px;
	}
	.bottom-left-container {
		position: absolute;
		bottom: 25px; /* 你可以根据需要调整位置 */
		left: 0px; /* 你可以根据需要调整位置 */
		border-radius: 0px 10px 10px 0px;
		background: rgba(0, 0, 0, 0.6);
	}
	.info-box {
		display: flex;
		align-items: center;
	}
	.info-text {
		margin-left: 15px;
		margin-right: 15px;
		margin-top: 15px;
		margin-bottom: 15px;
		font-family: DINPro-Bold;
		font-size: 16px;
		font-weight: bold;
		line-height: 1.5;
		text-align: center;
		text-transform: uppercase;
		letter-spacing: 0em;

		font-variation-settings: "opsz" auto;
		font-feature-settings: "kern" on;
		color: #ffffff;
	}
	.info-icon {
		margin-right: 15px;
		width: auto;
		height: 20px;
	}
	.video-overlay-play,
	.video-overlay-play_top {
		position: absolute;
		top: 50%;
		left: 50%;
		width: 60px;
		height: auto;
		transform: translate(-50%, -50%);
	}

	.bottom-left-container {
		position: absolute;
		bottom: 15px; /* 你可以根据需要调整位置 */
		left: 0px; /* 你可以根据需要调整位置 */
		border-radius: 0px 10px 10px 0px;
		background: rgba(0, 0, 0, 0.6);
	}
	.info-box {
		display: flex;
		align-items: center;
	}
	.info-text {
		margin-left: 10px;
		margin-right: 6px;
		margin-top: 10px;
		margin-bottom: 10px;
		font-family: DINPro-Bold;
		font-size: 12px;
		font-weight: bold;
		line-height: 1.5;
		text-align: center;
		text-transform: uppercase;
		letter-spacing: 0em;

		font-variation-settings: "opsz" auto;
		font-feature-settings: "kern" on;
		color: #ffffff;
	}
	.info-icon {
		margin-right: 10px;
		width: auto;
		height: 14px;
	}
}
</style>

<!-- f_meta -->
<style scoped>
.f_meta {
	text-align: start;
}
.f_meta_3 {
	font-size: 20px !important;
}
.f_meta_4 {
	margin-left: 10%;
	margin-right: 10%;
	margin-top: 10vh;
}

.f_meta_4 span {
	font-size: 26px;
	font-weight: bold;
	line-height: 1.5;
	text-transform: uppercase;
	letter-spacing: 0em;
	font-variation-settings: "opsz" auto;
	color: #ffffff;
	background: #6429ff;
	padding: 10px;
}

.f_meta_5 {
	width: 80%;
	margin-left: 10%;
	margin-top: 50px;
	display: flex;
	justify-content: space-between;
	margin-bottom: 0px;
}
.f_meta_5_list_center {
	width: 200px;
}

.f_meta_5_item {
	display: flex;
	align-items: center;
	margin-bottom: 20px;
}
.f_meta_5_item img {
	width: 8px !important;
	height: 8px !important;
}

.f_meta_5_item span {
	margin-left: 6px;
	font-size: 24px;
	font-weight: 600;
	line-height: 1.5;
	display: flex;
	align-items: center;
	text-transform: uppercase;
	letter-spacing: 0em;
	font-variation-settings: "opsz" auto;
	color: #ffffff;
}
@media screen and (max-width: 900px) {
	.f_meta_3 {
		font-size: 16px !important;
		width: 70%;
		line-height: 2;
		margin-left: 10px;
	}

	.f_meta_4 {
		margin-left: 10%;
		margin-right: 10%;
		margin-top: 10vh;
	}

	.f_meta_4 span {
		font-size: 16px;
		font-weight: bold;
		line-height: 1.5;
		text-transform: uppercase;
		letter-spacing: 0em;
		font-variation-settings: "opsz" auto;
		color: #ffffff;
		background: #6429ff;
		padding: 10px;
	}

	.f_meta_5 {
		width: 80%;
		margin-left: 10%;
		margin-top: 30px;
		display: flex;
		justify-content: space-around;
		margin-bottom: 60px;
	}

	.f_meta_5_list_center {
		width: 10%;
	}

	.f_meta_5_item {
		display: flex;
		align-items: center;
		margin-bottom: 20px;
	}
	.f_meta_5_item img {
		width: 8px !important;
		height: 8px !important;
	}

	.f_meta_5_item span {
		margin-left: 6px;
		font-size: 12px;
		font-weight: 600;
		line-height: 18px;
		display: flex;
		align-items: center;
		text-transform: uppercase;
		letter-spacing: 0em;
		font-variation-settings: "opsz" auto;
		color: #ffffff;
	}
}
</style>

<!-- f_contact -->
<style scoped>
.f_contact {
	display: none;
}
.f_contact_2 {
	margin-left: 10%;
	margin-right: 10%;
	margin-top: 8vh;
}
.f_contact_1 {
	margin-left: 10%;
	margin-right: 10%;
	margin-top: 5vh;
	margin-bottom: 100px;
}

.f_contact_1_item img {
	background: white;
	padding: 4px;
	border-radius: 4px;
	object-fit: contain;
}

.f_contact_1 span {
	font-size: 20px;
	font-weight: 400;
	line-height: 30px;
	align-items: center;
	letter-spacing: 0em;
	font-variation-settings: "opsz" auto;
	color: #ffffff;
	text-transform: lowercase;
}

.f_contact_3 {
	transform: rotate(180deg); /* 旋转 180 度 */
}
@media screen and (max-width: 900px) {
	.f_contact_2 {
		margin-top: 5vh;
	}
	.f_contact_1 {
		margin-top: 5vh;
		margin-bottom: 10vh;
		height: 100px;
		display: inline-block;
		text-align: center;
		align-items: center;
		align-content: center;
	}
}
</style>
<style scoped>
.f_back_content {
	margin-top: 30px;
	display: flex;
	align-content: center;
	align-items: center;
	text-align: center;
}

.f_back_content img {
	width: 80%;
	height: auto;
}
</style>

<style scoped>
.f_pic {
	padding-bottom: 40px;
	background: #6429ff;
}

.f_pic_content_left {
	margin-left: 10%;
	display: inline-grid;
	text-align: start;
	justify-content: space-around;
	min-height: 200px;
}

.f_pic_content_left_line {
	background: #8bffae;
	width: 30px;
	height: 6px;
	margin-bottom: 20px;
}

.f_pic_content_left_pic1 {
	background: white;
	align-items: center;
	align-content: center;
	text-align: center;
	width: 180px;
	height: 40px;
	margin-bottom: 20px;

	border-radius: 10px;
	color: #6429ff;
	font-size: 24px;
	font-weight: bold;
}
.f_pic_content_left_pic2 {
	width: 80%;
	font-size: 30px;
	font-weight: bold;
	line-height: 1.5;
	color: #ffffff;
	margin-bottom: 20px;
}
.f_pic_content_left_pic3 {
	font-size: 25px;
	line-height: 1.5;
	color: #ffffff;
	opacity: 0.5;
	margin-bottom: 20px;
}

.f_pic_content_right {
	margin-left: 5%;
	width: 60%;
	align-items: center;
	align-content: center;
	min-width: 300px;
}

.f_pic_content_right img {
	border-radius: 4px;
	width: 80%;
	height: auto;
}
@media screen and (max-width: 900px) {
	.f_pic_content_left {
		margin-left: 10%;
		margin-right: 10%;
		min-height: 200px;
	}

	.f_pic_content_left_pic1 {
		width: 140px;
		height: 30px;
		margin-bottom: 10px;
		font-size: 20px;
	}
	.f_pic_content_left_pic2 {
		width: 80%;
		font-size: 20px;
		line-height: 1.5;
		margin-bottom: 10px;
	}
	.f_pic_content_left_pic3 {
		font-size: 15px;
		opacity: 0.5;
		margin-bottom: 20px;
	}
	.f_pic_content_right {
		margin-left: 10%;
		width: 80%;
	}

	.f_pic_content_right img {
		border-radius: 4px;
		width: 100%;
		height: auto;
	}
}
</style>
